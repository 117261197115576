import ACVideo from './lib/ACVideo.js';
import ACLazyLoad from './lib/ACLazyLoad.js';
import ACModal from './lib/ACModal.js';

$(function() {

    console.log("Ready...");

    // Add callback to the end of a jQuery chain
    $.fn.call = function (fn, args, thisp) {
        fn.apply(thisp || this, args);
        return this;
    };

    // Wrap all video elements and add custom play controls
    $('video').each(ACVideo.iterate);

    // LazyLoad selected iamges
    $('.lazy').each(ACLazyLoad.iterate);
    var timer = setTimeout(function() {
        ACLazyLoad.load();
        clearTimeout(timer);
    }, 0);

    // Setup any modal windows
    $('.modal').each(ACModal.iterate);

    // Initiate the scrolling/revealing
    const scroller = scrollama();

    const collection = $('.project-card-list__item').add($('.project-story').children());
    scroller.setup({
        step: collection.get(),
        once: true,
        offset: 0.9
    })
    .onStepEnter(response => {
        $(response.element).addClass("scroller-reveal--display");
    });

    // Show any elements that are already visible
    const $window = $(window);
    const viewportTop = window.pageYOffset + $window.height();

    collection.each(function(){
        const $el = $(this);
        var top = $el.offset().top;
        const matrix = $el.css('transform').split(/[()]/)[1].split(',');

        top -= parseInt(matrix[5], 10);

        if ( top < viewportTop ) {
            $el.addClass("scroller-reveal--display");
        }
    });

    // Setup resize event
    window.addEventListener('resize', scroller.resize);
});